import '../src/css/App.css';

function App() {
  return (
    <div className='App'>
      <div className='hello'>
        <h1>This page is making. Please visit next time.</h1>
        <a href='https://github.com/lililliililil' target='blank'><p>copyright 2024 ⓒ barcode All rights reserved</p></a>
      </div>
    </div>
  );
}

export default App;
